[class^="icon-"],{
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon {
	&-logo:before {
		content: "\e900";
	}

	&-shirt:before {
		content: "\e901";
	}

	&-arrow-slider:before {
		content: "\e902";
	}

	&-arrow:before {
		content: "\e903";
	}
}