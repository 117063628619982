.nice-select {
	-webkit-tap-highlight-color: transparent;
	background-color: #F8F8F8;
	border: 1px solid white;
	box-sizing: border-box;
	clear: both;
	cursor: pointer;
	display: inline-block;
	font-family: inherit;
	font-size: 14px;
	font-weight: normal;
	height: 50px;
	line-height: 48px;
	outline: none;
	padding-left: 18px;
	padding-right: 30px;
	position: relative;
	text-align: left !important;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	white-space: nowrap;
	width: 300px;

	&:hover {
		border-color: #dbdbdb;
	}

	&:active,
	&.open,
	&:focus {
		// border-color: #999;
	}

	&:after {
		border-bottom: 2px solid #999;
		border-right: 2px solid #999;
		content: '';
		display: block;
		height: 5px;
		margin-top: -4px;
		pointer-events: none;
		position: absolute;
		right: 12px;
		top: 50%;
		-webkit-transform-origin: 66% 66%;
		-ms-transform-origin: 66% 66%;
		transform-origin: 66% 66%;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
		-webkit-transition: all 0.15s ease-in-out;
		transition: all 0.15s ease-in-out;
		width: 5px;
	}

	&.open {
		&:after {
			-webkit-transform: rotate(-135deg);
			-ms-transform: rotate(-135deg);
			transform: rotate(-135deg);
		}

		.list {
			opacity: 1;
			pointer-events: auto;
			-webkit-transform: scale(1) translateY(0);
			-ms-transform: scale(1) translateY(0);
			transform: scale(1) translateY(0);
		}
	}

	&.disabled {
		border-color: #ededed;
		color: #999;
		pointer-events: none;

		&:after {
			border-color: #cccccc;
		}
	}

	&.wide {
		width: 100%;

		.list {
			left: 0 !important;
			right: 0 !important;
		}
	}

	&.right {
		float: right;

		.list {
			left: auto;
			right: 0;
		}
	}

	&.small {
		font-size: 12px;
		height: 36px;
		line-height: 34px;

		&:after {
			height: 4px;
			width: 4px;
		}

		.option {
			line-height: 34px;
			min-height: 34px;
		}
	}

	.list {
		width: 300px;
		background-color: #fff;
		// border-radius: 5px;
		box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
		box-sizing: border-box;
		margin-top: 4px;
		opacity: 0;
		overflow: hidden;
		padding: 0;
		pointer-events: none;
		position: absolute;
		top: 100%;
		left: 0;
		-webkit-transform-origin: 50% 0;
		-ms-transform-origin: 50% 0;
		transform-origin: 50% 0;
		-webkit-transform: scale(0.75) translateY(-21px);
		-ms-transform: scale(0.75) translateY(-21px);
		transform: scale(0.75) translateY(-21px);
		-webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
		transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
		z-index: 9;

		&:hover {
			.option:not(:hover) {
				background-color: transparent !important;
			}
		}
	}

	.option {
		cursor: pointer;
		font-weight: 400;
		line-height: 40px;
		list-style: none;
		min-height: 40px;
		outline: none;
		padding-left: 18px;
		padding-right: 29px;
		text-align: left;
		-webkit-transition: all 0.2s;
		transition: all 0.2s;

		&:hover, 
		&.focus, 
		&.selected.focus {
			background-color: #f6f6f6;
		}

		&.selected {
			font-weight: bold;
		}

		&.disabled {
			background-color: transparent;
			color: #999;
			cursor: default; 
		}
	}
}

.no-csspointerevents {
	.nice-select {
		.list {
			display: none;
		}

		&.open {
			.list {
				display: block;
			}
		}
	}
}