@import url("normalize.less");
@import url("mixins.less");
@import url("fonts.css");
@import url("icons.less");

@import url("vendor/flickity.min.less");
@import url("vendor/nice-select.less");
@import url("vendor/jquery.fancybox.min.css");

// variables
@red: #c83748;
@title-font: 'Arkhip';
@font: 'GothamPro';

html,
body {
	width: 100%;
	min-width: 320px;
	height: 100%;
	overflow: hidden;
}

body {
	font-family: @font, sans-serif;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	-moz-font-feature-settings: 'liga', 'kern';

	background: #fff;
}

.grid {
	display: table;

	&__row {
		display: table-row;
		vertical-align: middle;
	}

	&__cell {
		display: table-cell;
		vertical-align: middle;
		position: relative;
		
	}
}

.container {
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
	box-sizing: border-box;

	&__viewport {
		width: 100%;
		height: 100%;
		overflow:hidden;
		position:relative;
		box-sizing: border-box;
	}

	&__overview {
		width: 100%;
		height: 100%;
		transition: left 1s;

		position:absolute;
		top:0; left:0;

		box-sizing: border-box;
		
		.flex(row, flex-start);

		&.animate {
			.transition(left 2s ease);
		}
	}

	&__scrollbar {
		position:absolute;
		left: 0; right: 0; bottom:0;
		background: #f1f1f1;
		width: 100%;
		height: 5px;
		display: none;

		&.disable {
			display:none;
		}
	}

	&__track {
		width: 100%;
		height:5px;
		position:relative;
	}

	&__thumb {
		background: #d3d3d3;
		height:5px;

		cursor:pointer;
		overflow:hidden;

		position:absolute;
		bottom: 0; left: 0; 
	}
}

.noSelect {
	user-select:none;
	-o-user-select:none;
	-moz-user-select:none;
	-khtml-user-select:none;
	-webkit-user-select:none;
}

.section {
	min-width: 100%;
	height: 100%;
	min-height: 600px;
	flex-shrink: 0;
	box-sizing: border-box;
}

.main {
	background: @red;
	position: relative;
	overflow: hidden;
	padding-left: 0;
	z-index: 200;

	&:after {
		content: "";
		position: absolute;
		top: 0; left: 0; bottom: 0;
		width: 30%;
		height: 100%;
		background: url("../img/bg-after.jpg") no-repeat;
		background-size: cover;
		z-index: 0;
	}

	&__video {
		position: absolute;
		right: 0; bottom: 0;
		z-index: 0;
		display: block;
		min-width: 100%;
		min-height: 100%;
		opacity: .2;
	}

	&__inner {
		position: relative;
		width: 100%; height: 100%;
		z-index: 10;
	}

	&__slider {
		width: 80%;
		height: 100%;
		padding: 0 5%; 
		box-sizing: border-box;
		position: relative;
		max-width: 1344px;
		max-height: 780px;
	}

	&__navigation {
		width: 20%;
	}
}

.slider {
	display: block;
	width: 100%;
	height: 80%;
	// background: #ededed;
	background: white;
	box-shadow: 12px 12px 16px rgba(0, 0, 0, .15);
	margin: 0 auto;
	box-sizing: border-box;
	position: relative;
	overflow: hidden;
	max-width: 1300px;

	&__item {
		width: 100%;
		height: 100%;
		padding: 40px;
		box-sizing: border-box; 
		// background-size: contain;
		background-repeat: no-repeat;
		background-position: right 50px;
		// background-position: left top;
		vertical-align: middle;
		// background-size: cover !important;
	}

	&__item_slide1 {

		.slider {
			&__inner {
				position: relative;
				background: url('../img/slides/slide-1/all.jpg') no-repeat;
				overflow: hidden;
				background-size: contain;

				// &::before {
				// 	position: absolute;
				// 	top: -80px; right: -50px;
				// 	width: 698px; height: 700px;
				// 	content: '';
				// 	background: url('../img/slides/slide-1/slide-add2.png') no-repeat;
				// }

				// &::after {
				// 	position: absolute;
				// 	bottom: 0; right: 0;
				// 	width: 1264px; height: 594px;
				// 	content: '';
				// 	background: url('../img/slides/slide-1/slide-add1.png') no-repeat;
				// }
			}
		}		
	}

	// &__inner {
	// 	height: 100%;
	// }

	&__text {
		height: 100%;
		max-width: 300px;
	}

	&__head {
		position: absolute;
		z-index: 1;
		left: 0;
		top: 170px;
		display: inline-block;
		vertical-align: middle;
		height: 170px;
		width: 320px;
		padding: 30px 50px 30px 30px;
		box-sizing: border-box;
		background: url('../img/slides/slide-1/slide-head.jpg') no-repeat;
	}

	&__title {
		display: inline-block;
		font-size: 35px;
		color: @red;
		text-transform: uppercase;
		font-weight: bold;
		vertical-align: middle;
	}

	// &__label {
	// 	display: block;
	// 	font-size: 10px;
	// 	margin: 10px 0;
	// 	font-family: @title-font;
	// 	text-transform: uppercase;
	// 	vertical-align: middle;
	// }

	&__label {
		display: block;
		font-size: 18px;
		margin: 10px 0;
		font-family: @font;
		vertical-align: middle;
		line-height: 1.4;
	}

	&__description {
		margin: 0;
		font-size: 13px;
		line-height: 15px;
		color: #666;
	}

	&__inner {
		width: 100%;
		height: 100%;
		background-position: center center !important;
	}
}

.flickity-prev-next-button {
	background: none;
	border: 1px solid #999;
	width: 50px;
	height: 50px;
	line-height: 50px;
	text-align: center;

	&:hover {
		background: none;
	}

	svg {
		position: relative;
		left: 0;
		top: 0;
		width: 15px;
		height: 15px;

		path {
			fill: #999 !important;
		}
	}
}

.navigation {
	width: 100%;
	height: 80%;
	box-sizing: border-box;
	vertical-align: middle;
	color: #fff;

	&__logo {
		color: #fff;
		font-size: 50px;
		text-decoration: none;
	}

	&__items {
		position: relative;
		height: 100%;
	}

	&__list {
		margin: 0; padding: 0;
		list-style: none;
		vertical-align: middle;
	}

	&__link {
		color: #fff;
		font-family: @title-font;
		text-decoration: none;
		font-size: 16px;
		text-transform: uppercase;
		line-height: 40px;
		position: relative;

		&:after {
			content: "";
			position: absolute;
			left: 0; right: 0; bottom: 0;
			width: 100%;
			height: 1px;
			background: #fff;
			.translate(0, 10px);
			opacity: 0;
			.transition();
		}

		&:hover {
			&:after {
				.translate(0, 1px);
				opacity: 1;
			}
		}
	}

	&__link_active {
		&:after {
			.translate(0, 1px);
			opacity: 1;
		}
	}

	&__shirt {
		position: absolute;
		left: 0; bottom: 10%;
		width: 160px;
		font-family: @title-font;
		text-transform: uppercase;
		color: #fff;
		text-decoration: none;

		i {
			font-size: 35px;
			display: inline-block;
		}

		span {
			display: inline-block;
			padding-left: 10px;
		}
	}

	&_bg {
		width: 20%;
		height: 100%;
		padding: 50px;
		background: @red url("../img/nav-bg.jpg") no-repeat;
		background-size: cover;
	}

	&__menu {
		display: none;
		position: absolute;
		left: 24px; top: 50%;
		transform: translateY(-50%);
		width: 22px; height: 16px;
		padding: 0;
		border: none;
		background: url('../img/menu.svg');
	}
}

.nav-small {
	display: table;
	width: 40px;
	height: 100%;
	background-color: @red;
	color: #fff;
	text-align: center;
	font-family: @title-font;
	text-transform: uppercase;
	text-decoration: none;
	font-size: 12px; 
	.transition();
	z-index: 1000;

	&:hover {
		opacity: .9;
	}

	&__inner {
		display: table-cell;
		vertical-align: middle;
	}

	&__label {
		display: block;
		.rotate(-90deg);
	}
}

.navigaton-fixes {
	position: fixed;
	height: 100%;
	top: 0; left: 0; bottom: 0;
	z-index: 100;

	.translate(~"calc(-100% + 40px)", 0);
	.transition();

	&:hover {
		.translate(0, 0);
	}

	.navigation__shirt {
		left: auto;
	}
}

.services {
	width: 100%;

	&__list {
		table-layout: fixed;
		width: 100%;
		height: 100%;
		margin: 0; padding: 0;
		list-style: none;
	}

	&__item {
		width: 20%;
		height: 100%;
		margin: 0; padding: 0;
		text-align: center;
		background: #fff;
		.transition();

		&:nth-child(odd) {
			background: #ededed;
		}

		&:hover {
			background: @red;

			.services__view {
				opacity: 1;
				.translate(0, 0);
			}
		}
	}

	&__link {
		position: relative;
		width: 100%;
		height: 100%;
		font-family: @font;
		text-decoration: none;
		text-transform: uppercase;
		font-size: 12px;
		color: #000;
		font-weight: 500;
		padding: 0 10px;
		box-sizing: border-box;
	}

	&__name {
		position: relative;
		z-index: 10;
		font-size: 14px;
		line-height: 18px;
	}

	&__label {
		position: absolute;
		top: 50%; left: 0; right: 0;
		.translate(0, -45%);
		font-size: 90px;
		color: #dbdbdb;
		font-family: @title-font;
	}

	&__view {
		font-family: @font;
		text-transform: none;
		font-weight: normal;
		color: #fff;
		.transition();
		.translate(0, -30px);
		opacity: 0; 

		span {
			display: block;
			padding: 20px;
		}

		i {
			font-size: 15px;
			display: block;
			.rotate(90deg);
		}
	}
}

.portfolio {
	width: 100%;

	&__inner {
		table-layout: fixed;
		width: 100%;
		height: 100%;
		padding: 50px 100px;
		box-sizing: border-box;
	}
	
	&__title {
		width: 100%;
		height: 10%;
	}

	&__filters {
		width: 100%;
		height: 20%;
	}

	&__slider {
		width: 100%;
		height: 70%;
	}
}

.title {
	font-size: 40px;
	margin: 0; padding: 0;
}

.title_b {
	color: black;
}

.p-slider {
	width: 100%;
	height: 100%;

	&__item {
		width: 33.33%;
		height: 80%;
		box-sizing: border-box;
		padding-right: 10px;
		box-sizing: border-box;
		opacity: 1;
		transition: opacity .2s;
		transform: translate3d( 0, 0, 0);

		&:hover {
			.p-slider__image::before {
				opacity: .1;
			}
		}
	}

	&__image {
		position: relative;
		display: block;
		width: 100%;
		height: 80%;
		max-width: 510px;
		max-height: 330px;
		background-color: #f9f9f9;
		background-repeat: no-repeat;
		background-size: cover;

		&::before {
			position: absolute;
			left: 0; top: 0; right: 0; bottom: 0;
			content: '';
			background: black;
			opacity: 0;
			transition: all .2s;
		}
	}

	&__title {
		display: block;
		text-transform: uppercase;
		font-weight: bold;
		margin: 10px 0 5px;
		max-width: 510px;
	}

	&__description {
		font-size: 13px;
	}

	.flickity-prev-next-button {
		left: 0; bottom: 0;
		.translate(0, 0);

		&.next {
			left: 60px;
		}
	}
}

.flickity-prev-next-button {
	cursor: pointer;
	transition: all .2s;

	&:hover {
		border-color: #c83748;

		& svg path {
			fill: #c83748 !important;
		}
	}

	& svg path {
		transition: all .3s;
	}
}

.team {
	width: 100%;
	overflow: hidden;

	// * {
	// 	cursor: none !important;
	// }

	&__slider {
		width: 100%;
		height: 100%;
		margin-left: 40px;

		.flickity-viewport {
			cursor: none !important;
		}
	}

	.flickity-prev-next-button:disabled {
		display: none;
	}

	&__item {
		width: 25%;
		height: 100%;
		background: rgba(255, 255, 255, 0.5);
		padding: 0 50px;
		box-sizing: border-box;
		position: relative;
		.transition();

		&:hover,
		&.active {
			color: #fff;

			.team__img {
				opacity: 1;
			}

			&:nth-child(1) {
				background: rgba(255, 153, 102, 0.35);
			}

			&:nth-child(2) {
				background: rgba(102, 0, 51, 0.35);
			}

			&:nth-child(3) {
				background: rgba(255, 153, 102, 0.35);
			}

			&:nth-child(4) {
				background: rgba(102, 0, 51, 0.35);
			}

			&:nth-child(5) {
				background: rgba(255, 153, 102, 0.35);
			}

			&:nth-child(6) {
				background: rgba(102, 0, 51, 0.35);
			}

			&:nth-child(7) {
				background: rgba(255, 153, 102, 0.35);
			}

			&:nth-child(8) {
				background: rgba(102, 0, 51, 0.35);
			}

			&:nth-child(9) {
				background: rgba(255, 153, 102, 0.35);
			}

			&:nth-child(10) {
				background: rgba(102, 0, 51, 0.35);
			}

			&:nth-child(11) {
				background: rgba(255, 153, 102, 0.35);
			}

			&:nth-child(12) {
				background: rgba(102, 0, 51, 0.35);
			}

			&:nth-child(13) {
				background: rgba(102, 0, 51, 0.35);
			}

			&:nth-child(14) {
				background: rgba(255, 153, 102, 0.35);
			}

			&:nth-child(15) {
				background: rgba(102, 0, 51, 0.35);
			}
		}
	}

	&__name {
		display: block;
		font-weight: bold;
		font-size: 25px;
		margin: 10px 0;
	}

	&__position {
	}

	&__img {
		// width: 100%;
		height: 100%;
		position: absolute;
		left: -100px; bottom: 0; right: 0;
		opacity: 0;
		.transition();
		z-index: -1;
	}

	&__email {
		display: block;
		color: #ccc;
		margin-top: 10px;
	}

	&__bottom {
		vertical-align: bottom;
	}
}

.contact {
	position: relative;
	width: 100%;

	&__inner {
		table-layout: fixed;
		width: 100%;
		height: 100%;
		padding: 50px 100px;
		box-sizing: border-box;
	}

	&__video {
		position: absolute;
		right: 0; bottom: 0;
		z-index: -1;
		display: block;
		min-width: 100%;
		min-height: 100%;
		opacity: .4;
	}

	&__form  {
		width: 63%;
	}	

	&__phones {
		width: 23%;
	}

	&__or {
		width: 14%;
		text-align: center;
		// color: #ccc;
		color: #c83748;
		font-family: @title-font;
		font-size: 20px;
	}

	&__label {
		display: block;
	}

	input,
	textarea {
		width: 100%;
		padding: 15px 20px;
		outline: none;
		margin: 10px 0;
		border: 1px solid #ccc;
		resize: none;
		box-sizing: border-box;
		font-size: 14px;

		&:focus {
			border-color: #c83748;
		}
	}

	textarea {
		height: 200px;
	}

	&__form-info {
		display: block;
		margin: 20px 0;
	}

	[type="submit"] {
		width: 100%;
		height: 50px;
		margin: 0; padding: 0;
		box-sizing: border-box;
		border: none;
		background-color: @red;
		color: #fff;
		outline: none;
		transition: all .2s;

		&:hover {
			background: white;
			color: #c83748;
			border: 1px solid #c83748;
		}
	}

	&__description {
		display: block;
		margin: 25px 0 10px;
	}

	&__value {
		display: block;
		font-weight: bold;
		color: currentColor;
		text-decoration: none;
	}

	.title {
		color: #c83748;
	}
}

@media (max-width: 870px) {
	body, html, .containter {
		width: auto;
		overflow: visible !important;
	}

	.container {
		&__viewport {
			height: auto;
		}

		&__overview {
			display: block;
			position: relative;
			overflow: initial;
		}
	}

	.title {
		display: none;
	}

	.section {
		position: relative;
		min-width: auto;
		min-height: auto;

		&::before {
			display: block;
			padding: 12px 0;
			width: 100%;
			font-family: @title-font;
			font-size: 20px;
			color: white;
			text-align: center;
			background-color: @red;
			background: @red url('../img/bg-section-m.jpg') repeat center;
			background-size: 100%;
		}
	}

	.grid {
		display: flex;
		flex-flow: row wrap;
		-webkit-box-orient: vertical;

		&__cell {
			position: static;
			display: inline-flex;
			flex: 1 100%;
			width: 100%;
		}

		&__row {
			width: 100%;
		}
	}

	.icon-logo {
		font-size: 42px;
	}

	.main {
		overflow: visible;
		background: url('../img/bg-section-m.jpg') bottom;

		&__video, &::after {
			display: none;
		}

		&__navigation {
			position: relative;
			order: 1;
			flex-basis: 100
		}

		&__slider {
			padding: 0;
			height: 254px;
			order: 2;
			flex-basis: 100%;
			background-color: white;
		}
	}

	.nav-small {
		display: none;
	}

	.navigation {
		&__list {
			display: block;
			padding: 48px 0 0 24px;
		}

		&__item {
			display: block;
		}

		&__items {
			// display: none;
			position: absolute;
			top: -100vh; left: 0;
			z-index: 999;
			width: 100%;
			height: 444px;
			background: url(../img/nav-bg.jpg) no-repeat #c83748 bottom;
			background-size: cover;
			opacity: 0;
			transition:500ms;
			overflow: hidden;
		}

		&__items_show {
			opacity: 1;
			top: 0;
		}

		&__shirt {
			position: static;
			margin: 10px 0 10px 24px;
		}

		&__logo {
			display: inline-block;
			margin: 0 auto;
			width: 150px;
			text-align: center;
		}

		&__menu {
			display: inline-block;
		}

		&__menu_close {
			position: relative;
			top: 14px;
			transform: none;
			background: none;

			&::before {
				position: absolute;
				left: 0; top: 0;
				content: '\00d7';
				font-size: 36px;
			}
		}

		&__item {
			margin-bottom: 20px;
		}

		&__link {
			font-size: 18px;
			text-transform: none;
		}
	}

	.grid.navigaton-fixes {
		display: none;
	}

	.slider {
		box-shadow: none;
		height: 90%;
		position: relative;
		top: 10px;
		border-top: 10px solid #eee;
		border-bottom: 10px solid #eee;

		&__item {
			padding: 0;
			background-position: right;
		}

		&__text {
			padding: 50px 0 0 20px;
			height: auto;
			display: block;
		}

		&__head {
			width: 100%;
		}

		&__title {
			font-size: 14px;
		}

		&__label {
			font-size: 8px;
		}

		&__description {
			font-size: 6px;
			text-transform: uppercase;
			max-width: 100px;
			line-height:  1.8;
		}
	}

	.flickity-prev-next-button {
		display: none;
	}

	.main__slider {
		display: none;
	}

	.services {
		transition: all .3s;
		margin-top: 1px;

		&::before {
			content: 'Услуги';
		}

		&__view {
			& span {
				padding: 16px
			}

			& span, i {
				opacity: 0;
			}
		}

		&__name {
			position: absolute;
			left: 50%; top: 50%;
			transform: translate(-50%,-50%);
			width: 200px;
		}

		&__label {
			font-size: 60px;
			top: 50%; left: 50%;
			transform: translate(-50%,-50%);
		}
	}

	.services_withmenu {
		margin-top: 150px;
	}

	.portfolio {
		&::before {
			content: 'Портфолио';
		}

		&__inner {
			padding: 0;
		}

		&__title {
			display: none;
		}

		&__filters {
			& .grid__cell {
				display: block;
			}
		}

		&__slider {
			padding-top: 20px;
			height: 300px;
		}
	}

	.p-slider {
		&__item {
			width: 100%;
			padding: 0;
		}

		&__title, &__description {
			padding: 0 10px;
		}

		&__image {
			margin: 0 auto;
			background-position: center;
			background-attachment: local;
		}
	}

	.nice-select {
		width: 100%;
		height: 30px;
		font-size: 13px;
		line-height: 28px;
		background-color: #ededed;

		& .list {
			width: 100%;
		}
	}

	.team {
		left: auto;
		height: 300px;

		&::before {
			content: 'Команда';
		}

		&::after {
			position: absolute;
			top: 10px;
			right: 50%;
			transform: translateX(calc(50% + 250px));
			width: 30px;
			height: 26px;
			content: '';
			background: url('../img/team.svg');
		}

		&__item, &__item.active, &__item:hover {
			padding: 0;
			width: 100%;

			&:nth-child(1n) {
				color: #fff;
			}

			&:nth-child(1) {
				background: rgba(255, 153, 102, 0.25);
			}

			&:nth-child(2) {
				background: rgba(102, 0, 51, 0.25);
			}

			&:nth-child(3) {
				background: rgba(255, 153, 102, 0.25);
			}

			&:nth-child(4) {
				background: rgba(102, 0, 51, 0.25);
			}

			&:nth-child(5) {
				background: rgba(255, 153, 102, 0.25);
			}

			&:nth-child(6) {
				background: rgba(102, 0, 51, 0.25);
			}

			&:nth-child(7) {
				background: rgba(255, 153, 102, 0.25);
			}

			&:nth-child(8) {
				background: rgba(102, 0, 51, 0.25);
			}

			&:nth-child(9) {
				background: rgba(255, 153, 102, 0.25);
			}

			&:nth-child(10) {
				background: rgba(102, 0, 51, 0.25);
			}

			&:nth-child(11) {
				background: rgba(255, 153, 102, 0.25);
			}

			&:nth-child(12) {
				background: rgba(102, 0, 51, 0.25);
			}

			&:nth-child(13) {
				background: rgba(102, 0, 51, 0.25);
			}

			&:nth-child(14) {
				background: rgba(255, 153, 102, 0.25);
			}

			&:nth-child(15) {
				background: rgba(102, 0, 51, 0.25);
			}
		}

		&__inner {
			display: block;
			text-align: center;
		}

		&__img {
			top: 0; left: 0; bottom: auto; right: auto;
			width: 100%;
			height: auto;
			opacity: 1;
		}

		&__slider {
			margin: 0;
		}
	}

	.contact {
		margin-top: 44px;

		&::before {
			content: 'Контакты';
		}

		&__inner {
			padding: 0;
		}

		&__form {
			padding: 0 10px;
			box-sizing: border-box;

			& form {
				width: 100%;
			}
		}

		&__label {
			width: 100%;
		}

		&__or {
			display: none;
		}

		&__phones {
			display: block;
			margin: 20px 0 0;
			padding: 1px 25px 25px;
			color: white;
			box-sizing: border-box;
			background: @red url('../img/bg-section-m.jpg') repeat center;
			background-size: cover;

			// &::before {
			// 	display: block;
			// 	padding: 10px 0;
			// 	width: 100%;
			// 	content: 'Контакты';
			// 	font-family: @title-font;
			// 	font-size: 20px;
			// 	color: white;
			// 	text-align: center;
			// }
		}
	}
}

#cursor {
	display: none;
	position  : absolute;
	z-index   : 10000;
	width     : 40px;
	height    : 40px;
	background: transparent url(../img/cursor.png) 0 0 no-repeat;
	pointer-events: none;

	&::before {
		position: absolute;
		left: -10px;
		top: 50%;
		transform: translateY(-50%) rotate(-45deg);
		content: '';
		width: 5px; height: 5px;
		border-top: 2px solid black;
		border-left: 2px solid black;
		animation: arrowLeft 1s infinite;
		opacity: 0;
	}

	&::after {
		position: absolute;
		right: -10px;
		top: 50%;
		transform: translateY(-50%) rotate(135deg);
		content: '';
		width: 5px; height: 5px;
		border-top: 2px solid black;
		border-left: 2px solid black;
		animation: arrowRight 1s infinite;
		animation-delay: .5s;
		opacity: 0;
	}
}

@keyframes arrowLeft {
	from {
		left: -10px;
	}
	to {
		left: -15px;
		opacity: 1;
	}
}

@keyframes arrowRight {
	from {
		right: -10px;
	}
	to {
		right: -15px;
		opacity: 1;
	}
}


.modal {
	position: fixed;
	width: 100%;
	background: #fff;
	top: 0; left: 0;
	z-index: 999;
	.transition();
	
	opacity: 0;
	visibility: hidden;

	&.show {
		opacity: 1;
		visibility: visible;
	}

	&__nav {
		position: fixed;
		z-index: 999;
		top: 50%;
		left: 0;
		width: calc(~'100% - 16px');
		transform: translateY(-50%);
	}

	&__nav-btn {
		position: absolute;
		top: 50%;
		width: 50px;
		height: 100vh;
		background: none;
		border: none;
		transform: translateY(-50%);
		transition: all .3s;

		&::before {
			position: absolute;
			top: 50%;
			left: 10px;
			content: '';
			transform: translateY(-50%);
			width: 25px;
			height: 1px;
			background: black;
		}

		&::after {
			position: absolute;
			content: '';
			width: 6px; height: 6px;
		}

		&:hover {
			background: rgba(0, 0, 0, .15);
		}

		span {
			display: inline-block;
			position: relative;
			left: 7px;
			transform: rotate(-90deg);
			transform-origin: top left;
		}
	}

	&__nav-btn_prev {
		left: 0;

		&::after {
			left: 12px;
			top: calc(~'50% - 1px');
			border-left: 1px solid black;
			border-top: 1px solid black;
			transform: rotate(-45deg) translateY(-50%);
		}
	}

	&__nav-btn_next {
		right: 0;

		&::before {
			left: auto;
			right: 10px;
		}

		&::after {
			right: 7px;
			top: calc(~'50% - 1px');
			border-right: 1px solid black;
			border-bottom: 1px solid black;
			transform: rotate(-45deg) translateY(-50%);
		}
	}

	.content {
		width: 100%;
		height: 100vh;
		margin: auto;
		overflow-y: scroll;
		
		.close {
			z-index: 999;
			width: 120px;
			height: 120px;
			background: @red;
			border-radius: 100%;
			position: absolute;
			top: -60px;
			margin: auto;
			left: 0; right: 0;
			text-align: center;

			img {
				width: 25px;
				margin-top: 70px;
			}
		}

		.head {
			height: 432px;
			color: #fff;
			.flex();
			justify-content: center;
			align-items: center;

			span {
				display: block;
				line-height: 86px;
			}

			.type {
				font-size: 33px;
			}

			.name, .discription {
				font-family: @font;
				font-weight: bold;
				text-transform: uppercase;
			}

			.name {
				font-size: 90px;
			}

			.discription {
				font-size: 45px;
			}
		}

		.inner {
			.brand {
				width: 1000px;
				margin: auto;

				.flex();
				align-items: center;
				font-size: 19px;

				img {
					padding: 75px;
				}
			}
			
			h4 {
				text-transform: uppercase;
				font-size: 28px;
				text-align: center;
			}

			.project {
				width: 1000px;
				margin: auto;

				p {
					font-size: 17px;
					line-height: 20px;
				}

				a {
					color: @red;
				}

				img {
					width: 100%;
					margin: 30px 0;
				}

				&__scroll {
					position: relative;
					margin: 60px 0 30px;
				    box-shadow: 0 12px 24px rgba(0,0,0,.1);
					
					&::before {
						position: absolute;
						top: -38px; left: 0;
						width: 100%; height: 38px;
						content: '';
						background: url('../img/browser-top.png') no-repeat;
						background-size: contain;
					}

					&::after {
						position: absolute;
						left: 50%; top: -25px;
						transform: translateX(-50%);
						content: attr(data-name);
						font-size: 12px;
					}
				}

				&__image {
					// padding-top: 38px;
					max-width: 100%;
					max-height: 700px;
					overflow-y: scroll;

					img {
						margin: 0;
					}
				}
			}

			.quote {
				background: #f9f9f9;

				.inner {
					// width: 1000px;
					margin: auto;
					.flex();
					align-items: center;

					.avatar {
						width: 150px;
						height: 150px;
						border-radius: 100%;
						margin: 100px;
					}

					p {
						font-size: 19px;

						span {
							display: block;
							font-size: 15px;
							margin-top: 20px;
						}
					}
				}

				&.case {
					.inner {
						display: block;
						text-align: center;
						margin-bottom: 20px;
					}

					a {
						text-decoration: none;
					}
				}
			}

			.team {

				.item {
					width: 25%;
					height: 420px;
					color: #fff;
					text-align: center;
					.flex();
					justify-content: center;
					align-items: flex-end;
					margin: 15px;
					box-sizing: border-box;

					span {
						margin: 0 !important;
					}

					.name {
						font-family: @title-font;
					}

					.function {
						font-size: 14px;
						font-family: @font;
					}
				}

				.flickity-prev-next-button {
					display: none;
				}

				&__slider {
					margin: 0;
					height: 500px;
				}

				&__item {
					height: inherit;
					text-align: center;
					z-index: 1;
				}

				&__img {
					z-index: -1;
					opacity: .6;
					left: 50%; 
					transform: translateX(-50%);
					height: 100%;
				}

				&__inner {
					
				}
			}

			
			.view {
				.flex();
				justify-content: center;
				margin: 30px 0;

				.button {
					background: @red;
					color: #fff;
					text-decoration: none;
					// border-radius: 50px;
					padding: 20px;
					box-sizing: border-box;
					.transition();

					&:hover {
						opacity: .7;
					}
				}
			}

			.case {
				padding: 60px;
				box-sizing: border-box;

				.item {
					// margin: 20px;

					&:nth-child(3n+2) {
						margin: 20px;
					}

					img {
						max-width: 100%;
					}
				}
				
				.all-project {
					text-align: center;
					padding: 20px 0;

					.button {
						background: #b0b0b0;
						color: #fff;
						text-decoration: none;
						// border-radius: 50px;
						padding: 20px;
						box-sizing: border-box;
						.transition();

						&:hover {
							opacity: .7;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 1000px) {
	.modal {
		.content {

			.inner {
				padding: 0 20px;

				.brand, .project, .quote .inner {
					width: auto;
				}

				.quote.case .inner {
					display: block;
					text-align: center;
				}

				.case .item {
					display: block;
				}

				.team {
					&__item {
						width: 50%;
					}

					&__img {
						width: auto;
					}
				}
			}
		}
	}
}

@media (max-width: 870px) {
	#cursor {
		display: none;
	}

	.team::after {
		display: none;
	}

	.modal {
		.content {
			.head {
				span {
					line-height: 1.4;
				}

				.title {
					display: block;
					padding: 0 20px;
				}

				.type {
					font-size: 27px;
				}

				.name {
					font-size: 60px;
				}

				.discription {
					font-size: 32px;
				}
			}

			.inner {
				.brand {
					display: block;

					img {
						display: block;
						margin: 0 auto;
					}
				}

				.quote .inner {
					display: block;
					padding: 20px;

					.avatar {
						display: block;
						margin: 50px auto;
					}
				}

				.quote.team {
					height: auto;

					&::before {
						display: none;
					}

					.inner {
						display: block;
					}

					.item {
						width: 100%;
						margin: 0 0 10px;
					}
				}

				.team {
					&__item {
						width: 100%;
					}
				}
			}
		}
	}
}

@media (max-width: 570px) {
	.modal {
		.content {
			.head {

				.type {
					font-size: 18px;
				}

				.name {
					font-size: 48px;
				}

				.discription {
					font-size: 22px;
				}
			}
			
			.inner {
				h4 {
					font-size: 24px;
				}

				.brand {
					img {
						padding: 25px 0;
					}
				}

				.quote {
					&.case {
						padding: 25px 0;

						.item {
							margin: 0 0 10px;
						}
					}

					&.team {
						.inner {
							padding: 0;
						}
					}
				}
			}

		}
	}
}