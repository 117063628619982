@font-face {
	font-family: 'GothamPro';

	src: url('GothamPro.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/GothamPro.otf')  format('opentype'),
		 url('../fonts/GothamPro.woff') format('woff'), 
		 url('../fonts/GothamPro.ttf')  format('truetype'), 
		 url('../fonts/GothamPro.svg#GothamPro') format('svg');

	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'GothamPro';
	
	src: url('../fonts/GothamPro-Medium.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/GothamPro-Medium.otf')  format('opentype'),
		 url('../fonts/GothamPro-Medium.woff') format('woff'), 
		 url('../fonts/GothamPro-Medium.ttf')  format('truetype'), 
		 url('../fonts/GothamPro-Medium.svg#GothamPro-Medium') format('svg');

	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'GothamPro';
	
	src: url('../fonts/GothamPro-Bold.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/GothamPro-Bold.woff') format('woff'), 
		 url('../fonts/GothamPro-Bold.ttf')  format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Arkhip';

	src: url('../fonts/Arkhip.eot?#iefix') format('embedded-opentype'),  
		 url('../fonts/Arkhip.woff') format('woff'), 
		 url('../fonts/Arkhip.ttf')  format('truetype'), 
		 url('../fonts/Arkhip.svg#Arkhip') format('svg');

	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'icomoon';
	
	src: url('../fonts/icomoon.eot?3tnft1');
	src: url('../fonts/icomoon.eot?3tnft1#iefix') format('embedded-opentype'),
		 url('../fonts/icomoon.ttf?3tnft1') format('truetype'),
		 url('../fonts/icomoon.woff?3tnft1') format('woff'),
		 url('../fonts/icomoon.svg?3tnft1#icomoon') format('svg');

	font-weight: normal;
	font-style: normal;
}