.flex(@direction: row, @justify: flex-start, @align: flex-start) {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;

	-webkit-flex-direction: @direction;
	flex-direction: @direction;
	
	-webkit-justify-content: @justify;
	justify-content: @justify;

	-webkit-align-items: @align;
	align-items: @align;
}

.transition(@all: all .4s ease 0s) {
	-webkit-transition: @all;
	-moz-transition: @all;
	-o-transition: @all;
	transition: @all;
}

.translate(@x, @y: 0, @z: 0) {
	-webkit-transform:   translate3d(@x, @y, @z);
	-moz-transform: 	 translate3d(@x, @y, @z);
	-ms-transform: 		 translate3d(@x, @y, @z);
	-o-transform: 		 translate3d(@x, @y, @z);
}

.scale(@scale: .9) {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;

	-webkit-transform:   scale(@scale);
	-moz-transform: 	 scale(@scale);
	-ms-transform: 		 scale(@scale);
	-o-transform: 		 scale(@scale);
}

.rotate(@deg) {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;

	-webkit-transform: rotate(@deg) translate3d(0, 0, 1px);
	-moz-transform: rotate(@deg) translate3d(0, 0, 1px);
	-ms-transform: rotate(@deg) translate3d(0, 0, 1px);
	-o-transform: rotate(@deg) translate3d(0, 0, 1px);
}

@base: 20px;

.font-size(@px) {
	font-size: unit(@px / @base, rem);
}

.line-height(@px) {
	line-height: unit(@px / @base, rem);
}

.width(@px) {
	width: unit(@px / @base, rem);
}

.height(@px) {
	height: unit(@px / @base, rem);
}